import React, { useEffect, useState } from 'react';
import {
  Button, Input, Modal, Select, Tooltip, Popover, Collapse, Checkbox, Radio,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { stringify } from 'query-string';
import Axios from 'axios';
import {
  getQuestionAction,
  addQuestionsAction,
  updateQuestionsAction,
  deleteQuestionsAction,
  addResponseAction,
  getQuestionDataMoreAction,
  getQuestionAdditionalDetailsAction,
} from '../SavedProjectResults/logic';
import AutoSuggest from '../AutoSuggest';
import {
  getautosuggestionCancelAction,
  getautosuggestionResetAction,
  getautosuggestvalueAction,
} from '../AddProjectName/logic';
import Loader from '../../../common/components/Loader';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import { getFormattedDate, getCookie } from '../../../utils';
import { TextType } from '../AddFilters/filter';
import { getCountryDataAction } from '../GeneralInformation/logic';

const { Option } = Select;
const { Panel } = Collapse;

const CountryQuestionModal = ({
  questionModal,
  setQuestionModal,
  projectID,
  setViewQuestionScreen,
}) => {
  const [questionList, setQuestionList] = useState([]);
  const dispatch = useDispatch();
  const getQuestionResponse = useSelector((reduxState) => reduxState.getQuestionResponse || {});
  const addQuestionsResponse = useSelector((reduxState) => reduxState.addQuestionsResponse || {});
  const updateQuestionsResponse = useSelector(
    (reduxState) => reduxState.updateQuestionsResponse || {},
  );
  const deleteQuestionsResponse = useSelector(
    (reduxState) => reduxState.deleteQuestionsResponse || {},
  );
  const addResponse = useSelector((reduxState) => reduxState.addResponse || {});
  const getQuestionAdditionalDetailsResponse = useSelector(
    (reduxState) => reduxState.getQuestionAdditionalDetailsResponse || {},
  );
  const getCountryData = useSelector((store) => store.getCountryData);
  const [innoplexusId, setInnoplexusId] = useState('');
  const [editQuestionModal, setEditQuestionModal] = useState(false);
  const [addResponseModal, setAddResponseModal] = useState(false);
  const [updateQuestionConfirmationModal, setUpdateQuestionConfirmationModal] = useState(false);
  const [deleteQuestionConfirmationModal, setDeleteQuestionConfirmationModal] = useState(false);
  const [from, setFrom] = useState(1);
  const [isFilterPopoverVisible, setIsFilterPopoverVisible] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({
    indication_name: [],
    question_type: [],
    overall_status: [],
    country: [],
  });

  const [openPanels, setOpenPanels] = useState([]);
  const [addQuestionModal, setAddQuestionModal] = useState(false);

  const [filterValues, setFilterValues] = useState([]);
  const [formState, setFormState] = useState([{ type: '' }]);
  const [formStateForResponse, setFormStateForResponse] = useState([{ type: '' }]);

  const [addQuestionData, setAddQuestionData] = useState({
    question_text: '',
    question_category: '',
    country: [],
  });

  const [optionChoice, setOptionChoice] = useState('');
  const [optionValue, setOptionValue] = useState(['']);
  const [countryList, setCountryList] = useState([]);

  const [showDataForResponse, setShowDataForResponse] = useState({
    question_id: '',
    questionText: '',
    question_category: '',
    country: [],
    additonal_details: {},
    options: {},
    additional_response_required: {},
  });

  const [responseOption, setResponseOption] = useState('');
  const [responseTextCapture, setResponseTextCapture] = useState([]);

  const [selectedCountryForResponse, setSelectedCountryForResponse] = useState('');
  const [comments, setComments] = useState('');

  // for edit data
  const [formStateForEdit, setFormStateForEdit] = useState([{ type: '' }]);
  const [formStateForResponseForEdit, setFormStateForResponseForEdit] = useState([{ type: '' }]);

  const [addQuestionDataForEdit, setAddQuestionDataForEdit] = useState({
    question_text: '',
    question_category: '',
    country: [],
  });

  const [optionChoiceForEdit, setOptionChoiceForEdit] = useState('');
  const [optionValueForEdit, setOptionValueForEdit] = useState(['']);

  useEffect(() => {
    dispatch(
      getQuestionAction({
        project_id: projectID,
        page: 1,
        size: 10,
      }),
    );
    dispatch(getCountryDataAction());
    dispatch(getQuestionAdditionalDetailsAction());
  }, []);

  useEffect(() => {
    if (getCountryData.flag) {
      setCountryList(getCountryData?.data?.distinct_countries);
    }
  }, [JSON.stringify(getCountryData)]);

  useEffect(() => {
    if (getQuestionAdditionalDetailsResponse.flag) {
      setFilterValues(getQuestionAdditionalDetailsResponse.data.data);
    }
  }, [JSON.stringify(getQuestionAdditionalDetailsResponse)]);

  useEffect(() => {
    if (getQuestionResponse.flag) {
      if (getQuestionResponse.data.data.length) {
        setQuestionList(getQuestionResponse.data.data);
      } else {
        setQuestionList([]);
      }
    }
  }, [JSON.stringify(getQuestionResponse)]);

  useEffect(() => {
    if (deleteQuestionsResponse.flag) {
      setDeleteQuestionConfirmationModal(false);
      dispatch(
        getQuestionAction({
          project_id: projectID,
          page: 1,
          size: 10,
        }),
      );
    }
  }, [JSON.stringify(deleteQuestionsResponse)]);

  useEffect(() => {
    if (addResponse.flag) {
      setAddResponseModal(false);
      setResponseOption('');
      setSelectedCountryForResponse('');
      setComments('');
      dispatch(
        getQuestionAction({
          project_id: projectID,
          page: 1,
          size: 10,
        }),
      );
    }
  }, [JSON.stringify(addResponse)]);

  useEffect(() => {
    if (addQuestionsResponse.flag) {
      setAddQuestionModal(false);
      setFormState([{ type: '' }]);
      setFormStateForResponse([{ type: '' }]);
      setAddQuestionData({
        question_text: '',
        question_category: '',
        country: [],
      });
      setOptionChoice('');
      setOptionValue(['']);
      dispatch(
        getQuestionAction({
          project_id: projectID,
          page: 1,
          size: 10,
        }),
      );
    }
  }, [JSON.stringify(addQuestionsResponse)]);

  const renderLabel = (key) => {
    if (key === 'age_range') {
      return 'Age Range'
    }
    if (key === 'dosage') {
      return 'Dosage'
    }
    if (key === 'indication') {
      return 'Indication'
    }
    if (key === 'line_of_therapy') {
      return 'Line of Therapy'
    }
    if (key === 'moa') {
      return 'Mechanism of Action/Drug Class/ Regiment'
    }
    if (key === 'roa') {
      return 'Route of Administration'
    }
    if (key === 'drug') {
      return 'Drug'
    }
    return ''
  }

  useEffect(() => {
    if (updateQuestionsResponse.flag) {
      setUpdateQuestionConfirmationModal(false);
      setEditQuestionModal(false);
      setInnoplexusId('');
      dispatch(
        getQuestionAction({
          project_id: projectID,
          page: 1,
          size: 10,
        }),
      );
    }
  }, [JSON.stringify(updateQuestionsResponse)]);

  const onIndicationChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'indications',
        }),
      );
    } else {
      dispatch(
        getautosuggestvalueAction({
          query: '',
          type: 'indications',
        }),
      );
    }
  };

  const columns = [
    {
      title: 'Question Text',
      dataIndex: 'question-text',
    },
    {
      title: 'Additional Details',
      dataIndex: 'additional-details',
    },
    {
      title: 'Question Category',
      dataIndex: 'question-category',
    },
    {
      title: 'Country',
      dataIndex: 'country',
    },
    {
      title: 'Response',
      dataIndex: 'response',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'add-response',
    },
  ];

  const renderHeader = () => {
    return (
      <div className="table-row table-header">
        {columns.map((colItm, index) => (
          <div
            className={colItm.title === 'Action' ? 'table-row-item add-respo' : `table-row-item ${colItm.dataIndex}`}
            key={index}
          >
            {colItm.title}
          </div>
        ))}
      </div>
    );
  };

  const renderHeaderForCollapse = (tableItm) => {
    const firstKey = Object.keys(tableItm.status)[0];
    const firstValue = Object.values(tableItm.status)[0];

    const firstKey1 = Object.keys(tableItm.additonal_details)[0];
    const firstValue1 = Object.values(tableItm.additonal_details)[0];
    return (
      <div className="table-row table-body project-specific panel-header-info">
        <div className="table-row-item question-text"> {tableItm.question_text}</div>
        <div className="table-row-item additional-details">
          {
            Object.keys(tableItm.additonal_details).length ? (
              <div className="additional-details-in">
                <div className="additional-details-in-con">
                  <div className="additional-details-key">{renderLabel(firstKey1)} : </div>
                  <div className="additional-details-val">{firstValue1} </div>
                </div>
              </div>
            ) : '-'
          }
        </div>
        <div className="table-row-item question-category"> {tableItm.question_category}</div>
        <div className="table-row-item country"> {tableItm.country.join(', ')}</div>
        <div className="table-row-item response" style={{ display: 'block' }}>
          {
            tableItm.responses.length ? (
              <>
                <div className="table-row-item-inside question-response">
                  <div className="question-response-content">
                    <div className="date-ques"> { tableItm.responses[0].country}</div>
                    <div className="date-ques">
                      {getFormattedDate(tableItm.responses[0].created_on * 1000)}
                    </div>
                    <div className="main-resp-con">
                      <div className="main-resp"> Main Response:</div>
                    </div>
                  </div>
                </div>
              </>

            ) : (
              <div className="table-row-item-inside question-response">-</div>
            )
          }
        </div>
        <div className="table-row-item status" style={{ display: 'block' }}>
          <div className="status-content">
            <div className="status-key">{firstKey} </div>
            <div className="status-value">{firstValue} </div>
          </div>
        </div>
      </div>
    );
  };

  const onChange = (key) => {
    setOpenPanels(key);
  };

  const onAddResponseClick = (data) => {
    let values = []

    if (data.additional_response_required.length === 1) {
      const names = data.additional_response_required.map((user) => {
        return user.key;
      });
      if (names[0] === '') {
        values = []
      } else {
        data.additional_response_required.forEach((object) => {
          object.value = '';
        });
        values = data.additional_response_required
      }
    } else {
      data.additional_response_required.forEach((object) => {
        object.value = '';
      });
      values = data.additional_response_required
    }
    setShowDataForResponse({
      question_id: data.id,
      question_text: data.question_text,
      question_category: data.question_category,
      country: data.country,
      additonal_details: data.additonal_details,
      options: data.options,
      additional_response_required: values,
    });
    setResponseTextCapture(values)
    setAddResponseModal(true);
  };

  const renderListOfQuestion = () => {
    return questionList.map((tableItm, index) => (
      <Collapse activeKey={openPanels} onChange={onChange}>
        <Panel
          header={
            !openPanels.includes(index.toString()) || openPanels[0] === undefined
              ? renderHeaderForCollapse(tableItm)
              : ''
          }
          key={index}
        >
          <div
            className={
              tableItm.childs.length
                ? 'table-row table-body parent-question-body'
                : 'table-row table-body no-parent-question-body'
            }
          >
            <div className="table-row table-body" key={index}>
              <div className="table-row-item question-text"> {tableItm.question_text}</div>
              <div className="table-row-item additional-details">
                <div className="additional-details-in">
                  {Object.keys(tableItm.additonal_details).length ? (Object.keys(tableItm.additonal_details).map((key) => (
                    <div className="additional-details-in-con">
                      <div className="additional-details-key">{renderLabel(key)} : </div>
                      <div className="additional-details-val">{tableItm.additonal_details[key]} </div>
                    </div>
                  ))) : '-'}
                </div>
              </div>
              <div className="table-row-item question-category"> {tableItm.question_category}</div>
              <div className="table-row-item country"> {tableItm.country.join(', ')}</div>
              <div className="table-row-item response" style={{ display: 'block' }}>
                {tableItm.responses.length ? (
                  tableItm.responses.map((itm) => {
                    return (
                      <>
                        <div className="table-row-item-inside question-response">
                          <div className="question-response-content">
                            <div className="country-date">
                              <div className="date-ques"> {itm.country}</div>
                              <div className="date-ques">
                                {getFormattedDate(itm.created_on * 1000)}
                              </div>
                            </div>
                            <div className="date-ques">
                              {itm.name}
                            </div>
                            <div className="main-resp-con">
                              <div className="main-resp"> Main Response:</div>
                              <div className="text-ques">
                                {itm.response_text}
                              </div>
                            </div>
                            <div className="additonal-res-required">
                              <div className="additonal-res-required-title"> Additional Response Required: </div>
                              <div className="additonal-res-required-content">
                                {itm.additional_response_required.length ? (
                                  itm.additional_response_required.map((data) => {
                                    return (
                                      <div className="additonal-res-required-body">
                                        <div className="additonal-res-required-key">{renderLabel(data.key)}:  </div>
                                        <div className="additonal-res-required-value">{data.value ? data.value : '-'} </div>
                                      </div>
                                    );
                                  })) : '-'}
                              </div>
                            </div>
                          </div>
                          <div className="comments-sec-content">
                            <div className="comments-sec-title"> Comments: </div>
                            <div className="comments-sec-value"> {itm.comments ? itm.comments : '-'}</div>
                          </div>
                        </div>
                        <br />
                      </>
                    );
                  })
                ) : (
                  <div className="table-row-item-inside question-response">-</div>
                )}
              </div>
              <div className="table-row-item status" style={{ display: 'block' }}>
                {' '}
                {Object.keys(tableItm.status).map((key) => (
                  <div className="status-content">
                    <div className="status-key">{key}: </div>
                    <div className="status-value">{tableItm.status[key]} </div>
                  </div>
                ))}
              </div>
              <div className="table-row-item add-respo">
                <Button
                  title="Add Response"
                  className="add-icon"
                  disabled={tableItm.status === 'Archived'}
                  onClick={() => {
                    onAddResponseClick(tableItm);
                  }}
                />
                <Button
                  onClick={() => onEditQuestion(tableItm)}
                  title="Edit Question"
                  className="edit-icon"
                  disabled={Object.values(tableItm.status).includes('Answered')}
                />
                <Button
                  title="Delete Question"
                  className="del-icon"
                  disabled={Object.values(tableItm.status).includes('Answered')}
                  onClick={() => {
                    setInnoplexusId(tableItm.innoplexus_id);
                    setDeleteQuestionConfirmationModal(true);
                  }}
                />
              </div>
            </div>
          </div>

        </Panel>
      </Collapse>
    ));
  };

  const onEditQuestion = (tableItm) => {
    setInnoplexusId(tableItm.innoplexus_id);
    setAddQuestionDataForEdit({
      question_text: tableItm.question_text,
      question_category: tableItm.question_category,
      country: tableItm.country,
    });
    const data = Object.keys(tableItm.additonal_details).map((key) => {
      return {
        type: key,
        data: tableItm.additonal_details[key],
      };
    });
    if (data.length) {
      setFormStateForEdit(data);
    } else {
      setFormStateForEdit([''])
    }

    const data1 = tableItm.additional_response_required.map((item) => {
      return {
        type: item.key,
        data: item.mandatory,
      };
    });
    if (data1.length) {
      setFormStateForResponseForEdit(data1)
    } else {
      setFormStateForResponseForEdit([{ type: '' }])
    }
    setOptionChoiceForEdit(tableItm.options.type);
    if (tableItm.options.choices.length) {
      setOptionValueForEdit(tableItm.options.choices);
    } else {
      setOptionValueForEdit(['']);
    }
    setEditQuestionModal(true);
  };

  const onAddApi = () => {
    const additionalDetails = formState.reduce(
      (obj, item) => Object.assign(obj, { [item.type]: item.data }),
      {},
    );

    const dataForAdditionalResp = formStateForResponse.map((item) => {
      return {
        key: item.type,
        mandatory: item.data,
      };
    });

    const status = addQuestionData.country.reduce(
      (obj, item) => Object.assign(obj, { [item]: 'Not Answered' }),
      {},
    );

    let data12 = []

    if (dataForAdditionalResp.length) {
      const a = dataForAdditionalResp.map((value) => value.key === '');
      if (a[0] === true) {
        data12 = []
      } else {
        data12 = dataForAdditionalResp
      }
    }

    const body = {
      project_id: projectID,
      parent_id: '',
      question_text: addQuestionData.question_text,
      question_category: addQuestionData.question_category,
      status,
      country: addQuestionData.country,
      additonal_details: additionalDetails,
      options: {
        type: optionChoice,
        choices:
          optionChoice === 'Multiple Choice' || optionChoice === 'Single Choice' ? optionValue.filter(Boolean) : [],
      },
      additional_response_required: data12,
    };

    dispatch(
      addQuestionsAction({
        body,
      }),
    );
  };

  const onDeleteApiCall = () => {
    dispatch(
      deleteQuestionsAction({
        innoplexus_id: innoplexusId,
      }),
    );
  };

  const onUpdateApiCall = () => {
    const additionalDetails = formStateForEdit.reduce(
      (obj, item) => Object.assign(obj, { [item.type]: item.data }),
      {},
    );

    const dataForAdditionalResp = formStateForResponseForEdit.map((item) => {
      return {
        key: item.type,
        mandatory: item.data,
        value: '',
      };
    });

    const status = addQuestionDataForEdit.country.reduce(
      (obj, item) => Object.assign(obj, { [item]: 'Not Answered' }),
      {},
    );
    const body = {
      project_id: projectID,
      parent_id: '',
      question_text: addQuestionDataForEdit.question_text,
      question_category: addQuestionDataForEdit.question_category,
      status,
      country: addQuestionDataForEdit.country,
      additonal_details: additionalDetails,
      options: {
        type: optionChoiceForEdit,
        choices: optionValueForEdit.filter(Boolean),
      },
      additional_response_required: dataForAdditionalResp,
    };

    dispatch(
      updateQuestionsAction({
        params: {
          innoplexus_id: innoplexusId,
        },
        body,
      }),
    );
  };

  const onAddResponseApi = () => {
    const status = {
      [selectedCountryForResponse]: 'Answered',
    };

    const data1 = Object.keys(responseTextCapture).map((key) => {
      return responseTextCapture[key];
    });

    const data = {
      response_text:
        showDataForResponse.options.type === 'Multiple Choice'
          ? responseOption.join(',')
          : responseOption,
      status,
      country: selectedCountryForResponse,
      comments,
      additional_response_required: data1,
    };
    dispatch(
      addResponseAction({
        param: {
          question_id: showDataForResponse.question_id,
        },
        body: {
          data,
        },
      }),
    );
  };

  const loadMoreRowsForList = () => {
    dispatch(
      getQuestionDataMoreAction({
        project_id: projectID,
        page: from + 1,
        size: 10,
      }),
    );
    setFrom(from + 1);
  };

  const handleChange = (value, key) => {
    const tempFilters = { ...selectedFilters };
    tempFilters[key] = [value];
    setSelectedFilters(tempFilters);
  };

  const onClearClick = () => {
    setSelectedFilters({
      indication_name: [],
      question_type: [],
      overall_status: [],
      country: [],
    });
    setIsFilterPopoverVisible(false);
    dispatch(
      getQuestionAction({
        project_id: projectID,
        page: 1,
        size: 10,
      }),
    );
  };

  const onApplyClick = () => {
    setIsFilterPopoverVisible(false);
    dispatch(
      getQuestionAction({
        project_id: projectID,
        // project_id: projectID,
        page: 1,
        size: 10,
        filters: JSON.stringify({ ...selectedFilters }),
      }),
    );
  };

  const handleCountryChange = (value) => {
    const tempFilters = { ...selectedFilters };
    const key = 'country'
    tempFilters[key] = value;
    setSelectedFilters(tempFilters);
  }

  const renderFilterContent = () => {
    return (
      <div className="analysis-filter-popover">
        <div className="analysis-filter-section">
          <div className="analysis-filter-section-filter">
            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Indication Name</div>
              <AutoSuggest
                apiCall={onIndicationChangeInput}
                apiName="autosuggestdata"
                onChange={(val) => handleChange(val, 'indication_name')}
                selections={selectedFilters.indication_name}
                setValue={(val) => handleChange(val, 'indication_name')}
                cancelAction={getautosuggestionCancelAction}
                resetAction={getautosuggestionResetAction}
                key="indication"
                defaultValue={selectedFilters.indication_name}
              />
            </div>

            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Question Type</div>
              <Select
                onChange={(val) => handleChange(val, 'question_type')}
                value={selectedFilters.question_type}
              >
                <Option value="Project Specific"> Project Specific</Option>
                <Option value="Generic"> Generic</Option>
              </Select>
            </div>

            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Country</div>
              <Select
                showSearch
                placeholder="Select country"
                mode="multiple"
                value={selectedFilters.country}
                onChange={(val) => handleCountryChange(val)}
              >
                {countryList
                      && countryList?.map((itm) => {
                        return <Option value={itm}>{itm}</Option>;
                      })}
              </Select>

            </div>

            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Status</div>
              <Select
                onChange={(val) => handleChange(val, 'overall_status')}
                value={selectedFilters.overall_status}
              >
                <Option value="Answered"> Answered</Option>
                <Option value="Not Answered"> Not Answered</Option>
              </Select>
            </div>
          </div>
        </div>
        <div className="filter-submit-btns">
          <Button className="clear-btn" onClick={() => onClearClick()}>
            Clear
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            onClick={() => onApplyClick()}
            disabled={
              selectedFilters?.indication_name?.length === 0
              && selectedFilters?.question_type?.length === 0
              && selectedFilters?.country?.length === 0
              && selectedFilters?.overall_status?.length === 0
            }
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  const addQuestionBtnClick = () => {
    setAddQuestionModal(true);
  };

  const handleDropdown = (idx, value) => {
    setFormState((s) => {
      return [...s.slice(0, idx), { type: value }, ...s.slice(idx + 1)];
    });
  };

  const getData = (f, idx) => {
    switch (f.type) {
      case 'indication':
      case 'dosage':
      case 'moa':
      case 'roa':
      case 'line_of_therapy':
      case 'age_range':
      case 'drug':
        return (
          <TextType
            type={f.type}
            state={f}
            setState={setFormState}
            index={idx}
            fdata={filterValues}
          />
        );

      default:
        return <></>;
    }
  };

  const handleDropdownForResponse = (idx, value) => {
    setFormStateForResponse((s) => {
      return [...s.slice(0, idx), { type: value }, ...s.slice(idx + 1)];
    });
  };

  const handleDropdownForResponseForEdit = (idx, value) => {
    setFormStateForResponseForEdit((s) => {
      return [...s.slice(0, idx), { type: value }, ...s.slice(idx + 1)];
    });
  };

  const onChangeRadioBtn = (e, type, index) => {
    setFormStateForResponse((s) => {
      return [...s.slice(0, index), { type, data: e.target.value }, ...s.slice(index + 1)];
    });
  };

  const onChangeRadioBtnForEdit = (e, type, index) => {
    setFormStateForResponseForEdit((s) => {
      return [...s.slice(0, index), { type, data: e.target.value }, ...s.slice(index + 1)];
    });
  };

  const handleRemoveForResponse = (index) => {
    setFormStateForResponse((s) => {
      const oldState = [...s];
      oldState.splice(index, 1);
      return [...oldState];
    });
  };

  const handleAddForResponse = () => {
    setFormStateForResponse((s) => [...s, { type: '' }]);
  };

  const handleRemoveForResponseForEdit = (index) => {
    setFormStateForResponseForEdit((s) => {
      const oldState = [...s];
      oldState.splice(index, 1);
      return [...oldState];
    });
  };

  const handleAddForResponseForEdit = () => {
    setFormStateForResponseForEdit((s) => [...s, { type: '' }]);
  };

  const getDataForResponse = (f, idx) => {
    switch (f.type) {
      case 'indication':
      case 'dosage':
      case 'moa':
      case 'roa':
      case 'line_of_therapy':
      case 'age_range':
      case 'drug':
        return (
          <div className="radio-sec">
            <Radio.Group onChange={(e) => onChangeRadioBtn(e, f.type, idx)}>
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
          </div>
        );

      default:
        return <></>;
    }
  };

  const getDataForResponseForEdit = (f, idx) => {
    switch (f.type) {
      case 'indication':
      case 'dosage':
      case 'moa':
      case 'roa':
      case 'line_of_therapy':
      case 'age_range':
      case 'drug':
        return (
          <div className="radio-sec">
            <Radio.Group
              onChange={(e) => onChangeRadioBtnForEdit(e, f.type, idx)}
              value={f.data === 'yes' || f.data === 'Yes' ? 'Yes' : 'No'}
            >
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
          </div>
        );

      default:
        return <></>;
    }
  };
  const dropDataForResponse = (f, idx) => {
    return (
      <>
        <Select
          value={f.type}
          className="select-drodown-item"
          onChange={(e) => handleDropdownForResponse(idx, e)}
          style={{ width: 200 }}
        >
          {filterValues
            && filterValues.map((l, index) => {
              return (
                <Option
                  key={index}
                  value={l.key}
                  disabled={isDropDownDisabled(l.key, 'addResponse')}
                >
                  {l.value}
                </Option>
              );
            })}
        </Select>

        <div>{getDataForResponse(f, idx)}</div>
      </>
    );
  };

  const dropDataForResponseForEdit = (f, idx) => {
    return (
      <>
        <Select
          value={f.type}
          className="select-drodown-item"
          onChange={(e) => handleDropdownForResponseForEdit(idx, e)}
          style={{ width: 200 }}
        >
          {filterValues
            && filterValues.map((l, index) => {
              return (
                <Option
                  key={index}
                  value={l.key}
                  disabled={isDropDownDisabled(l.key, 'editQuestionResponse')}
                >
                  {l.value}
                </Option>
              );
            })}
        </Select>

        <div>{getDataForResponseForEdit(f, idx)}</div>
      </>
    );
  };

  const handleAdd = () => {
    setFormState((s) => [...s, { type: '' }]);
  };

  const handleRemove = (index) => {
    setFormState((s) => {
      const oldState = [...s];
      oldState.splice(index, 1);
      return [...oldState];
    });
  };

  const isDropDownDisabled = (key, type) => {
    if (type === 'addQuestion') {
      return formState?.filter((a) => {
        return key === a.type
      }).length;
    }
    if (type === 'editQuestion') {
      return formStateForEdit?.filter((a) => {
        return key === a.type
      }).length;
    }
    if (type === 'addResponse') {
      return formStateForResponse?.filter((a) => {
        return key === a.type
      }).length;
    }
    if (type === 'editQuestionResponse') {
      return formStateForResponseForEdit?.filter((a) => {
        return key === a.type
      }).length;
    }
    return ''
  };

  const dropData = (f, idx) => {
    return (
      <>
        <Select
          value={f.type}
          className="select-drodown-item"
          onChange={(e) => handleDropdown(idx, e)}
          style={{ minWidth: 200 }}
        >
          {filterValues
            && filterValues.map((l, index) => {
              return (
                <Option
                  key={index}
                  value={l.key}
                  disabled={isDropDownDisabled(l.key, 'addQuestion')}
                >
                  {l.value}
                </Option>
              );
            })}
        </Select>

        <div>{getData(f, idx)}</div>
      </>
    );
  };

  const renderAdditionalResponseDetails = () => {
    return (
      <div className="filter-wrapper">
        <div className="filter-wrapper-con">
          <div className="filter-wrapper-title">Details</div>
          <div className="filter-wrapper-field"> Mandatory?</div>
        </div>
        {formStateForResponse
          && formStateForResponse.map((f, idx) => {
            return (
              <div key={idx} className="newfilter">
                <div className="filter-sec" style={{ display: 'flex' }}>
                  {dropDataForResponse(f, idx)}
                  <>
                    {formStateForResponse.length > 1 && (
                      <Button
                        onClick={() => handleRemoveForResponse(idx)}
                        className="plus-btn minus-btn"
                      >
                        -
                      </Button>
                    )}
                    {idx === formStateForResponse.length - 1 && (
                      <Button onClick={handleAddForResponse} className="plus-btn">
                        +
                      </Button>
                    )}
                  </>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderAdditionalResponseDetailsForEdit = () => {
    return (
      <div className="filter-wrapper">
        <div className="filter-wrapper-con">
          <div className="filter-wrapper-title">Details</div>
          <div className="filter-wrapper-field"> Mandatory?</div>
        </div>
        {formStateForResponseForEdit
          && formStateForResponseForEdit.map((f, idx) => {
            return (
              <div key={idx} className="newfilter">
                <div className="filter-sec" style={{ display: 'flex' }}>
                  {dropDataForResponseForEdit(f, idx)}
                  <>
                    {formStateForResponseForEdit.length > 1 && (
                      <Button
                        onClick={() => handleRemoveForResponseForEdit(idx)}
                        className="plus-btn minus-btn"
                      >
                        -
                      </Button>
                    )}
                    {idx === formStateForResponseForEdit.length - 1 && (
                      <Button onClick={handleAddForResponseForEdit} className="plus-btn">
                        +
                      </Button>
                    )}
                  </>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const onOptionChange = (e, idx) => {
    const list = [...optionValue];
    list[idx] = e.target.value;
    setOptionValue(list);
  };

  const onOptionChangeForEdit = (e, idx) => {
    const list = [...optionValueForEdit];
    list[idx] = e.target.value;
    setOptionValueForEdit(list);
  };

  const removeDefaultOption = (idx) => {
    const list = [...optionValue];
    list.splice(idx, 1);
    if (!list.length) {
      setOptionValue(['']);
    } else {
      setOptionValue(list);
    }
  };
  const removeDefaultOptionForEdit = (idx) => {
    const list = [...optionValueForEdit];
    list.splice(idx, 1);
    if (!list.length) {
      setOptionValueForEdit(['']);
    } else {
      setOptionValueForEdit(list);
    }
  };
  const addNewOption = () => {
    setOptionValue([...optionValue, '']);
  };

  const addNewOptionForEdit = () => {
    setOptionValueForEdit([...optionValueForEdit, '']);
  };

  const renderAdditionalDetails = () => {
    return (
      <div className="filter-wrapper">
        {formState
          && formState.map((f, idx) => {
            return (
              <div key={idx} className="newfilter">
                <div
                  className="filter-sec"
                  style={{
                    display: 'flex',
                    margin: 10,
                  }}
                >
                  {dropData(f, idx)}
                  <>
                    {formState.length > 1 && (
                      <Button onClick={() => handleRemove(idx)} className="plus-btn minus-btn">
                        -
                      </Button>
                    )}
                    {idx === formState.length - 1 && (
                      <Button onClick={handleAdd} className="plus-btn">
                        +
                      </Button>
                    )}
                  </>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderAddQuestionList = () => {
    return (
      <div className="add-question-list">
        <div className="add-question-list-content">
          <div className="combine-border-view-list no-combine-border-view-list">
            {/* <div className="combine-border-view-list-title">Question Details</div> */}
            <div className="combine-border-view-list-data">
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title"> Question Text
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Input
                    onChange={(e) => setAddQuestionData({
                      ...addQuestionData,
                      question_text: e.target.value,
                    })}
                    value={addQuestionData.question_text}
                  />
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title"> Question Category
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Select
                    onChange={(value) => setAddQuestionData({
                      ...addQuestionData,
                      question_category: value,
                    })}
                    value={addQuestionData.question_category}
                  >
                    <Option value="Project Specific"> Project Specific</Option>
                    <Option value="Generic"> Generic</Option>
                  </Select>
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title">Country
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Select
                    showSearch
                    placeholder="Select country"
                    mode="multiple"
                    value={addQuestionData.country}
                    onChange={(val) => setAddQuestionData({
                      ...addQuestionData,
                      country: val,
                    })}
                  >
                    {countryList
                      && countryList?.map((itm) => {
                        return <Option value={itm}>{itm}</Option>;
                      })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="add-question-list-content-input border-view-list">
            <div className="add-question-list-content-input-title"> Additional Details</div>
            <div className="add-question-list-content-input-data"> {renderAdditionalDetails()}</div>
          </div>
          <div className="option-setion border-view-list">
            <div className="add-question-list-content-input-title">Response Options:</div>
            <div className="add-question-list-content-input">
              <div className="add-question-list-content-input-data">
                <div className="add-question-list-content-input-data-select">
                  <div className="add-question-list-content-input-data-title">Type</div>
                  <Select onChange={(val) => setOptionChoice(val)} value={optionChoice}>
                    <Option value="Free Text"> Free Text</Option>
                    <Option value="Multiple Choice"> Multiple Choice</Option>
                    <Option value="Single Choice"> Single Choice</Option>
                  </Select>
                </div>
                {optionChoice === 'Multiple Choice' || optionChoice === 'Single Choice' ? (
                  <div className="genrated-inputs-content-choice">
                    <div className="genrated-inputs-content-choice-title">Choices: </div>
                    <div className="genrated-inputs-content-choice-data">
                      {optionValue.map((itm, childIndex) => {
                        return (
                          <div className="genrated-inputs-content-choice-value genrated-inputs-content">
                            <Input onChange={(e) => onOptionChange(e, childIndex)} value={itm} />
                            <Button
                              className="remove-btn-icon plus-btn"
                              title="remove"
                              onClick={() => removeDefaultOption(childIndex)}
                            >
                              -
                            </Button>
                            <div className="add-btn">
                              {optionValue.length - 1 === childIndex && (
                                <Button className="plus-btn" onClick={addNewOption}>
                                  +
                                </Button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="add-question-list-content-input add-question-last-input border-view-list">
          <div className="add-question-list-content-input-title">Additional Details Requested</div>
          <div className="add-question-list-content-input-data">
            {renderAdditionalResponseDetails()}
          </div>
        </div>
      </div>
    );
  };

  const onOptionsChecked = (e, itm) => {
    if (e.target.checked) {
      setResponseOption((val) => [...val, itm]);
    } else {
      const obj = responseOption.filter((val) => val !== itm);
      setResponseOption(obj);
    }
  };

  const onRadioBtnChange = (val) => {
    setResponseOption(val);
  };

  const onAdditionalRespChange = (e, itm) => {
    const newArray = responseTextCapture.map((item) => {
      if (item.key === itm.key) {
        return { ...item, value: e.target.value };
      }
      return item;
    });
    setResponseTextCapture(newArray);
  };

  const handleRemoveForEdit = (idx) => {
    setFormStateForEdit((s) => {
      const oldState = [...s];
      oldState.splice(idx, 1);
      return [...oldState];
    });
  };

  const handleAddForEdit = () => {
    setFormStateForEdit((s) => [...s, { type: '' }]);
  };

  const handleDropdownForEdit = (idx, value) => {
    setFormStateForEdit((s) => {
      return [...s.slice(0, idx), { type: value }, ...s.slice(idx + 1)];
    });
  };

  const getDataForEdit = (f, idx) => {
    switch (f.type) {
      case 'indication':
      case 'dosage':
      case 'moa':
      case 'roa':
      case 'line_of_therapy':
      case 'age_range':
      case 'drug':
        return (
          <TextType
            type={f.type}
            state={f}
            setState={setFormStateForEdit}
            index={idx}
            fdata={filterValues}
          />
        );

      default:
        return <></>;
    }
  };

  const dropDataForEdit = (f, idx) => {
    return (
      <>
        <Select
          value={f.type}
          className="select-drodown-item"
          onChange={(e) => handleDropdownForEdit(idx, e)}
          style={{ minWidth: 200 }}
        >
          {filterValues
            && filterValues.map((l, index) => {
              return (
                <Option
                  key={index}
                  value={l.key}
                  disabled={isDropDownDisabled(l.key, 'editQuestion')}
                >
                  {l.value}
                </Option>
              );
            })}
        </Select>

        <div>{getDataForEdit(f, idx)}</div>
      </>
    );
  };

  const renderAdditionalDetailsForEdit = () => {
    return (
      <div className="filter-wrapper">
        {formStateForEdit
          && formStateForEdit.map((f, idx) => {
            return (
              <div key={idx} className="newfilter">
                <div
                  className="filter-sec"
                  style={{
                    display: 'flex',
                    margin: 10,
                  }}
                >
                  {dropDataForEdit(f, idx)}
                  <>
                    {formStateForEdit.length > 1 && (
                      <Button
                        onClick={() => handleRemoveForEdit(idx)}
                        className="plus-btn minus-btn"
                      >
                        -
                      </Button>
                    )}
                    {idx === formStateForEdit.length - 1 && (
                      <Button onClick={handleAddForEdit} className="plus-btn">
                        +
                      </Button>
                    )}
                  </>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const exportNetworksClicked = () => {
    const filename = 'question_list_records.xlsx'
    const params = {
      project_id: projectID,
      is_downloading: true,
      filters: JSON.stringify({ ...selectedFilters }),
    }
    Axios({
      url: `${process.env.apiUrl}v0/projects/getQuestions?${stringify(params)}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click();
        return null;
      })
      .catch((e) => {
        return e;
      })
  }

  const isDisabled = () => {
    const data = responseTextCapture.filter((itm) => itm.mandatory === 'Yes')
    if (data.length) {
      const h = data.map((itm) => (itm.mandatory === 'Yes' && itm.value !== ''))
      if (h.includes(false)) {
        return true
      }
      return false
    }
    return false
  }

  const checkOption = () => {
    if (showDataForResponse.options.type === 'Single Choice' || showDataForResponse.options.type === 'Multiple Choice') {
      if (responseOption.length === 0) {
        return true
      }
      return false
    }
    if (showDataForResponse.options.type === 'Free Text') {
      if (responseOption === '') {
        return true
      }
      return false
    }
    return false
  }

  const renderResponseModalContent = () => {
    return (
      <>
        <div className="response-question-section">
          <div className="response-question-section-group">
            <div className="response-question-section-content">
              <div className="response-question-section-content-title"> Question Text</div>
              <div className="response-question-section-content-data">
                {' '}
                {showDataForResponse.question_text}
              </div>
            </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-title"> Question Category</div>
              <div className="response-question-section-content-data">
                {' '}
                {showDataForResponse.question_category}
              </div>
            </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-title"> Country</div>
              <div className="response-question-section-content-data">
                {' '}
                {showDataForResponse.country.join(', ')}
              </div>
            </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-title">
                {' '}
                Choose the country you have to answer:
                <span style={{ color: 'red' }}> *</span>
              </div>
              <div className="response-question-section-content-data select-option-view">
                <Select
                  value={selectedCountryForResponse}
                  className="select-drodown-item"
                  onChange={(e) => setSelectedCountryForResponse(e)}
                  style={{ width: 200 }}
                >
                  {showDataForResponse.country
                    && showDataForResponse.country.map((l, index) => {
                      return (
                        <Option key={index} value={l}>
                          {l}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </div>
            <div className="response-question-section-content-title resp-text"> Additional Details </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-data select-option-view">
                {' '}
                {Object.keys(showDataForResponse.additonal_details).length !== 0 ? (Object.keys(showDataForResponse.additonal_details).map((key, index) => (
                  <div className="respon-key" key={index}>
                    <div className="respon-key-title"> {renderLabel(key)}</div>
                    <div className="respon-key-data">
                      {showDataForResponse.additonal_details[key]}
                    </div>
                  </div>
                ))) : (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '20px',
                  }}
                  > No data available
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="response-question-section-group addi-req-section response-option">
            {showDataForResponse.options.choices.length === 0 && showDataForResponse.options.type === '' ? (
              <div className="option-section">
                <div className="option-title"> Response Option: </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingBottom: '20px',
                }}
                > No data available
                </div>
              </div>

            ) : (
              <div className="option-section">
                <div className="option-title"> Response Option: </div>
                <div className="response-question-section-content">
                  <div className="response-question-section-content-title option-type"> Type: </div>
                  <div className="response-question-section-content-data">
                    {' '}
                    {showDataForResponse.options.type}
                  </div>
                </div>
                {showDataForResponse.options.type === 'Multiple Choice' ? (
                  <div className="option-content">
                    <div className="option-content-title"> Choices : </div>
                    {' '}
                    <div className="option-content-data">
                      {showDataForResponse.options.choices.map((itm) => {
                        return (
                          <div className="respon-key">
                            <div className="respon-key-data">
                              <Checkbox onChange={(e) => onOptionsChecked(e, itm)} />
                            </div>
                            <div className="respon-key-title"> {itm}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : showDataForResponse.options.type === 'Single Choice' ? (
                  <div className="option-content">
                    <div className="option-content-title"> Choices : </div>
                    {' '}
                    <div className="option-content-data">
                      {showDataForResponse.options.choices.map((itm) => {
                        return (
                          <div className="respon-key">
                            <div className="respon-key-data">
                              <Radio.Group onChange={() => onRadioBtnChange(itm)} value={responseOption}>
                                <Radio value={itm} />
                              </Radio.Group>
                            </div>
                            <div className="respon-key-title"> {itm}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="respon-key-free-text">
                    <div className="respon-key-choice"> Choice: </div>
                    <Input
                      onChange={(e) => setResponseOption(e.target.value)}
                      value={responseOption}
                    />
                  </div>
                )}
              </div>

            )}
            <div className="response-question-section-content addi-req-section">
              <div className="response-question-section-content-title">
                {' '}
                Additional Response Required
              </div>
              {
                showDataForResponse.additional_response_required.length !== 0 ? (
                  <div className="response-question-section-content-data">
                    <div className="filter-wrapper-con">
                      <div className="filter-wrapper-title">Details</div>
                      <div className="filter-wrapper-field"> Mandatory?</div>
                      <div className="filter-wrapper-response"> Response</div>
                    </div>
                    {' '}
                    {showDataForResponse.additional_response_required.map((itm) => {
                      return (
                        <div className="addtional-info-sec">
                          <div className="addtional-info-sec-com">
                            <div className="addtional-info-sec-key">{renderLabel(itm.key)} </div>
                            <div className="addtional-info-se-mand"> {itm.mandatory} </div>
                          </div>
                          <div className="addtional-info-sec-input">
                            {' '}
                            <Input onChange={(e) => onAdditionalRespChange(e, itm)} />{' '}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingBottom: '20px',
                    }}
                  >
                    No data available

                  </div>
                )
              }

            </div>
            <div className="response-question-section-content comment-section-response">
              <div className="response-question-section-content-title"> Comments</div>
              <div className="response-question-section-content-data">
                {' '}
                <Input onChange={(e) => setComments(e.target.value)} value={comments} />
              </div>
            </div>
          </div>
          <div className="footer-sec">
            <Button
              onClick={() => onAddResponseApi()}
              disabled={isDisabled() || checkOption() || selectedCountryForResponse === ''}
            > Add Response
            </Button>
          </div>
        </div>
      </>
    );
  };

  const renderEditQuestionContent = () => {
    return (
      <div className="add-question-list">
        <div className="add-question-list-content">
          <div className="combine-border-view-list no-combine-border-view-list">
            {/* <div className="combine-border-view-list-title">Question Details</div> */}
            <div className="combine-border-view-list-data">
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title"> Question Text
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Input
                    value={addQuestionDataForEdit.question_text}
                    onChange={(e) => {
                      setAddQuestionDataForEdit({
                        ...addQuestionDataForEdit,
                        question_text: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title"> Question Category
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Select
                    value={addQuestionDataForEdit.question_category}
                    onChange={(val) => {
                      setAddQuestionDataForEdit({
                        ...addQuestionDataForEdit,
                        question_category: val,
                      });
                    }}
                  >
                    <Option value="Project Specific"> Project Specific</Option>
                    <Option value="Generic"> Generic</Option>
                  </Select>
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title">Country
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Select
                    onChange={(val) => {
                      setAddQuestionDataForEdit({
                        ...addQuestionDataForEdit,
                        country: val,
                      });
                    }}
                    value={addQuestionDataForEdit.country}
                    mode="multiple"
                  >
                    {countryList
                    && countryList?.map((itm) => {
                      return <Option value={itm}>{itm}</Option>;
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="add-question-list-content-input border-view-list">
            <div className="add-question-list-content-input-title"> Additional Details</div>
            <div className="add-question-list-content-input-data">
              {' '}
              {renderAdditionalDetailsForEdit()}
            </div>
          </div>
          <div className="option-setion border-view-list">
            <div className="add-question-list-content-input-title">Response Option: </div>
            <div className="add-question-list-content-input">
              <div className="add-question-list-content-input-data">
                <div className="add-question-list-content-input-data-select">
                  <div className="add-question-list-content-input-data-title">Type</div>
                  <Select
                    onChange={(val) => {
                      setOptionChoiceForEdit(val);
                    }}
                    value={optionChoiceForEdit}
                    style={{ minWidth: 200 }}
                  >
                    <Option value="Free Text"> Free Text</Option>
                    <Option value="Multiple Choice"> Multiple Choice</Option>
                    <Option value="Single Choice"> Single Choice</Option>
                  </Select>
                </div>
                {optionChoiceForEdit === 'Multiple Choice'
              || optionChoiceForEdit === 'Single Choice' ? (
                <div className="genrated-inputs-content-choice">
                  <div className="genrated-inputs-content-choice-title">Choices: </div>
                  <div className="genrated-inputs-content-choice-data">
                    {optionValueForEdit.map((itm, childIndex) => {
                      return (
                        <div className="genrated-inputs-content-choice-value genrated-inputs-content">
                          <Input
                            onChange={(e) => onOptionChangeForEdit(e, childIndex)}
                            value={itm}
                          />
                          <Button
                            className="remove-btn-icon plus-btn"
                            title="remove"
                            onClick={() => removeDefaultOptionForEdit(childIndex)}
                          >
                            -
                          </Button>
                          <div className="add-btn">
                            {optionValueForEdit.length - 1 === childIndex && (
                              <Button className="plus-btn" onClick={addNewOptionForEdit}>
                                +
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                  ) : (
                    ''
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="add-question-list-content-input add-question-last-input border-view-list">
          <div className="add-question-list-content-input-title">Additional Details Requested</div>
          <div className="add-question-list-content-input-data">
            {renderAdditionalResponseDetailsForEdit()}
          </div>
        </div>
        <div className="response-question-section">
          <div className="footer-sec">
            <Button
              onClick={() => setUpdateQuestionConfirmationModal(true)}
              disabled={addQuestionDataForEdit.question_category === '' || addQuestionDataForEdit.question_text === ''
      || addQuestionDataForEdit.country.length === 0}
            > Update
            </Button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Modal
        open={questionModal}
        onCancel={() => {
          setQuestionModal(false);
          setViewQuestionScreen(true)
        }}
        className="question-modal"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Loader loading={getQuestionResponse.loading}>
          <div className="country-profile-question">
            <div className="question-modal-content">
              <div className="question-modal-content-header">
                <div className="question-modal-content-header-left">
                  <div className="back-btn-header">
                    <Button
                      className="back-btn"
                      onClick={() => {
                        setQuestionModal(false)
                        setViewQuestionScreen(true)
                      }}
                    >
                      {' '}
                      Back
                    </Button>
                  </div>
                  <div className="question-modal-content-view-title">
                    {' '}
                    Question and Answer History
                  </div>
                </div>
                <div className="question-modal-content-header-right">
                  <div className="export-to-csv-btn export-to-csv-network-btn">
                    <Button
                      className="export-to-csv-btn-icon export-networks-button"
                      title="Export"
                      onClick={() => exportNetworksClicked()}
                    />
                  </div>
                  <Popover
                    overlayClassName="filter-runanalysis-popover filter-count-popover"
                    placement="left"
                    title="Filter by:"
                    content={renderFilterContent()}
                    trigger="click"
                    open={isFilterPopoverVisible}
                    onOpenChange={(visibled) => {
                      setIsFilterPopoverVisible(visibled);
                    }}
                  >
                    <div className="filter-icon">
                      <Tooltip title="Filters">
                        <div className="filter-icon-img" />
                      </Tooltip>
                    </div>
                  </Popover>
                </div>
              </div>
              <div className="question-modal-content-view">
                <div className="question-modal-content-view-table">
                  {renderHeader()}
                  <Loader loading={getQuestionResponse.loading}>
                    <div className="table-body-section">
                      <LazyLoadDiv
                        className="card-list scrollbar"
                        id="analysis-list"
                        total={getQuestionResponse.data.total}
                        currentTotal={(getQuestionResponse.data.data || []).length}
                        loadMoreRows={() => loadMoreRowsForList()}
                        height="calc(100vh - 276px)"
                      >
                        {questionList.length ? (
                          renderListOfQuestion()
                        ) : (
                          <div className="no-data-found-msg-country-question"> No data available</div>
                        )}
                      </LazyLoadDiv>
                    </div>
                  </Loader>
                  <div className="footer-button-wrapper footer-button-wrapper-pd save-analysis add-overlay-to-me add-no-overlay-to-me">
                    <div className="plus-add-btn">
                      <button
                        onClick={() => addQuestionBtnClick()}
                        type="button"
                        className="plus-btn"
                      >
                        <Tooltip title="Add Question">
                          <span className="title-plus">+</span>
                        </Tooltip>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Loader>
      </Modal>

      {addResponseModal && (
        <Modal
          open={addResponseModal}
          onCancel={() => {
            setAddResponseModal(false);
            setComments('');
            setSelectedCountryForResponse('');
            setResponseOption('');
          }}
          className="add-response-modal"
          title="Add Response"
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          {renderResponseModalContent()}
        </Modal>
      )}

      {updateQuestionConfirmationModal && (
        <Modal
          open={updateQuestionConfirmationModal}
          onCancel={() => setUpdateQuestionConfirmationModal(false)}
          onOk={() => onUpdateApiCall()}
          okText="Yes"
          cancelText="No"
          className="update-conf-modal"
        >
          <Loader loading={updateQuestionsResponse.loading}>
            <div className="update-conf-modal-title">
              {' '}
              Do you really want to update this question?
            </div>
          </Loader>
        </Modal>
      )}
      {deleteQuestionConfirmationModal && (
        <Modal
          open={deleteQuestionConfirmationModal}
          onCancel={() => setDeleteQuestionConfirmationModal(false)}
          onOk={() => onDeleteApiCall()}
          okText="Yes"
          cancelText="No"
          className="update-conf-modal"
        >
          <div className="update-conf-modal-title">
            {' '}
            Do you really want to delete this question? Once deleted, the question will NOT be
            available anywhere in LandSCAPE.
          </div>
        </Modal>
      )}
      {addQuestionModal && (
        <Modal
          title="Add Question"
          open={addQuestionModal}
          onCancel={() => {
            setAddQuestionModal(false);
            setFormState([{ type: '' }]);
            setFormStateForResponse([{ type: '' }]);
            setAddQuestionData({
              question_text: '',
              question_category: '',
              country: [],
            });
            setOptionChoice('');
            setOptionValue(['']);
          }}
          footer={null}
          closable={false}
          className="update-conf-modal"
        >
          <Loader loading={addQuestionsResponse.loading}>{renderAddQuestionList()}
            <div className="footer-button-wrapper footer-button-wrapper-pd">
              <Button
                className="cancel-wrapper cancel-btn ant-btn-default"
                onClick={() => {
                  setAddQuestionModal(false);
                  setFormState([{ type: '' }]);
                  setFormStateForResponse([{ type: '' }]);
                  setAddQuestionData({
                    question_text: '',
                    question_category: '',
                    country: [],
                  });
                  setOptionChoice('');
                  setOptionValue(['']);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="save-wrapper primary-btn ant-btn-primary"
                onClick={() => onAddApi()}
                disabled={addQuestionData.question_category.length === 0
                  || addQuestionData.question_text === ''
                  || addQuestionData.country.length === 0}
              >
                Add
              </Button>
            </div>
          </Loader>
        </Modal>
      )}

      {editQuestionModal && (
        <Modal
          title="Edit Question"
          className="update-conf-modal"
          open={editQuestionModal}
          onCancel={() => setEditQuestionModal(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          {renderEditQuestionContent()}
        </Modal>
      )}
    </div>
  );
};

export default CountryQuestionModal;
