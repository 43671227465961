import React, { useState, useEffect } from 'react';
import {
  Input, Button, Form, Spin,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import { getotherautosuggestvalueAction, getautosuggestionResetAction } from '../AddProjectName/logic';
import OutsideClick from '../../../common/components/OutsideClick';
import { getParentDetailsForCenterAction, getParentDetailsForCenterResetAction } from '../../components/AddInvestigator/logic';

const { Search } = Input

const OtherResearchAutoSuggest = ({
  otherCenter, autosuggestData, setAutosuggestData, setIdCounter,
  idCounter,
}) => {
  const [result, setResult] = useState([{ id: idCounter, result: [[]] }]);
  const apiResponse = useSelector((state) => state.autosuggestotherdata);
  const parentDetails = useSelector((state) => state.getParentDetailsForCenterResponse || {});

  const dispatch = useDispatch()

  const [indexForParent, setIndexForParent] = useState(0)
  const [indexForResult, setIndexForResult] = useState(0)

  const addAutoSuggestData = () => {
    setAutosuggestData([...autosuggestData, {
      id: idCounter + 1, value: '', displayDropdown: false, displayText: '', parent_institution: '', is_alliance: '',
    }])
    setResult([...result, { id: idCounter + 1, result: [[]] }])
    setIdCounter(idCounter + 1)
  }

  useEffect(() => {
    if (parentDetails.flag) {
      if (parentDetails?.data?.data?.length) {
        if (otherCenter !== undefined && otherCenter.length < 1) {
          const newData = [...autosuggestData]
          const id = newData.findIndex((item) => item.id === indexForParent)
          newData[id].parent_institution = parentDetails?.data?.data[0].name
          newData[id].is_alliance = parentDetails?.data?.data[0].alliance_type
          setAutosuggestData(newData)
          dispatch(getParentDetailsForCenterResetAction())
        } else {
          const merge = autosuggestData.map((item) => {
            const match = parentDetails?.data?.data.find((d) => d.center_id === item.value)
            if (match) {
              return {
                ...item,
                parent_institution: match.name,
                is_alliance: match.alliance_type,
              }
            }
            return item
          })
          setAutosuggestData(merge)
          dispatch(getParentDetailsForCenterResetAction())
        }
      } else {
        const newData = [...autosuggestData]
        const id = newData.findIndex((item) => item.id === indexForParent)
        if (id >= 0) {
          newData[id].parent_institution = ''
          newData[id].is_alliance = ''
          setAutosuggestData(newData)
        }
        dispatch(getParentDetailsForCenterResetAction())
      }
    }
  }, [JSON.stringify(parentDetails)])

  useEffect(() => {
    if (otherCenter && otherCenter?.length) {
      const key = otherCenter.map((itm) => itm.key)
      dispatch(getParentDetailsForCenterAction({
        site_id: key.toString(),
      }))
    }
  }, [])

  useEffect(() => {
    if (otherCenter?.length) {
      const data = otherCenter.map((item, index) => {
        setIdCounter(index)
        return ({
          id: index,
          value: item.key,
          displayDropdown: false,
          displayText: item.value,
        })
      })
      setAutosuggestData(data)
      setResult(otherCenter.map((_, index) => {
        return { id: index, result: [[]] }
      }))
    } else if (otherCenter?.new?.length) {
      const data = otherCenter?.new?.map((item, index) => {
        setIdCounter(index)
        return ({
          id: index,
          value: item.id,
          displayDropdown: false,
          displayText: item.name,
        })
      })
      setAutosuggestData(data)
      setResult(otherCenter?.new?.map((_, index) => {
        return { id: index, result: [[]] }
      }))
    }
  }, [])

  const handleChange = (value, index) => {
    const newData = [...autosuggestData]
    const id = newData.findIndex((item) => item.id === index)
    newData[id].value = value
    newData[id].displayText = value
    setAutosuggestData(newData)
    if (value.trim().length > 2) {
      setIndexForResult(index)
      dispatch(
        getotherautosuggestvalueAction({
          query: value.trim(),
          type: 'sites',
          filters: JSON.stringify({ parent_flag: false }),
        }),
      )
      newData[id].displayDropdown = true;
      setAutosuggestData(newData)
      dispatch(getautosuggestionResetAction())
    } else {
      setResult((prev) => prev.map((itm) => {
        if (itm.id === id) {
          return { ...itm, result: [[]] }
        }
        return itm
      }))
      newData[id].displayDropdown = false
      newData[id].parent_institution = ''
      newData[id].is_alliance = ''
      setAutosuggestData(newData)
      dispatch(getautosuggestionResetAction())
    }
  }

  useEffect(() => {
    if (apiResponse.flag && !apiResponse.loading) {
      if (apiResponse.data.data.length > 0) {
        setResult((prev) => prev.map((itm) => {
          if (itm.id === indexForResult) {
            return { ...itm, result: apiResponse.data.data }
          }
          return itm
        }))
      } else {
        setResult((prev) => prev.map((itm) => {
          if (itm.id === indexForResult) {
            return { ...itm, result: [] }
          }
          return itm
        }))
      }
    }
  }, [JSON.stringify(apiResponse)]);

  const autoSuggestionItemClicked = (value, index) => {
    const newData = [...autosuggestData]
    const id = newData.findIndex((item) => item.id === index)
    newData[id].value = value.value
    newData[id].displayText = value.key
    newData[id].displayDropdown = false
    setAutosuggestData(newData)
    setIndexForParent(index)
    dispatch(getParentDetailsForCenterAction({
      site_id: value.value,
    }))
  }

  const removeAutosuggestData = (index) => {
    setAutosuggestData(autosuggestData.filter((item) => item.id !== index))
    setResult(result.filter((item) => item.id !== index))
  }

  return (
    <div className="other-res-center-content">
      <div className="title-wrapper other-research-center">Other Research Centers</div>
      {
        autosuggestData.map(({
          id, displayDropdown, displayText, parent_institution, is_alliance,
        }, index) => (
          <>
            <div
              className="filter-sec"
            >
              <div className="other-res-search-content">
                <Form.Item
                  label={`Child ${index + 1}`}
                  className="title-wrapper other-research-center"
                  name="othercenter"
                >
                  <Search
                    value={displayText}
                    onChange={(e) => handleChange(e.target.value, id)}
                    placeholder="search"
                    allowClear
                  />

                  <div className="search-dropdown-result-content">
                    {displayDropdown && result.find((item) => item.id === id)?.result && result.find((item) => item.id === id)?.result.length > 0 ? (
                      <OutsideClick
                        onClickOutside={() => {
                          const newData = [...autosuggestData]
                          newData[id].displayDropdown = false
                          newData[id].value = ''
                          newData[id].displayText = ''
                          newData[id].is_alliance = ''
                          newData[id].parent_institution = ''

                          setAutosuggestData(newData)
                        }}
                      >
                        <div className="search-dropdown-result">
                          <div className="search-dropdown-result-list">
                            <Loader loading={apiResponse.loading}>
                              {
                          result.find((item) => item.id === id)?.result.map((res, i) => (
                            <div key={i}>
                              <div>
                                <div
                                  className="search-dropdown-result-list-card"
                                  role="presentation"
                                  key={i}
                                  onClick={() => autoSuggestionItemClicked(res, id)}
                                >
                                  <div className="ant-select-item-option autocompl-section-option">
                                    <div className="ant-select-item-option-content">
                                      <div className="label-wrap">{res.key}</div>
                                      <div className="address-wrap"><span className="label-title">Address :</span><span className="desc">{res?.address?.address_line1 ? res?.address?.address_line1 : '-'}</span></div>
                                      <div className="city-wrap"><span className="label-title">City:</span><span className="desc">{res?.address?.city ? res.address.city : '-'}</span></div>
                                      <div className="state-wrap"><span className="label-title">State:</span><span className="desc">{res?.address?.state ? res.address.state : '-'}</span></div>
                                      <div className="country-wrap"><span className="label-title">Country:</span><span className="desc">{res?.address?.country ? res.address.country : '-'}</span></div>
                                      <div className="zip-wrap"><span className="label-title">Zip:</span><span className="desc">{res?.address?.zip ? res.address.zip : '-'}</span></div>
                                      <div className="address-wrap"><span className="label-title">Alliance member:</span><span className="desc">{res?.alliance_member_name ? res.alliance_member_name : '-'}</span></div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                          ))
                        }

                            </Loader>
                          </div>
                        </div>
                      </OutsideClick>
                    ) : ''}
                  </div>
                </Form.Item>
              </div>
              <div className="combine-div">
                <div className="title-card">
                  <Form.Item
                    label="Parent Institution"
                    className="title-wrapper other-research-center"
                  >

                    {displayDropdown ? <Spin /> : (
                      <div className="main-head-wrap-title" style={{ minWidth: '100%' }}>
                        {parent_institution || '-'}
                      </div>
                    )}
                  </Form.Item>
                </div>
                <div className="title-card">
                  <Form.Item
                    label="Is Alliance?"
                    className="title-wrapper other-research-center"
                  >

                    {displayDropdown ? <Spin /> : (
                      <div className="main-head-wrap-title" style={{ minWidth: '100%' }}>
                        {is_alliance === 'AM' ? 'Yes' : is_alliance === '' ? 'No' : '-' }
                      </div>
                    )}
                  </Form.Item>
                </div>
              </div>

              <>
                {autosuggestData.length > 1 && (
                <Button onClick={() => removeAutosuggestData(id)} className="plus-btn minus-btn">
                  -
                </Button>
                )}
                {index === autosuggestData.length - 1 && (
                <Button onClick={addAutoSuggestData} className="plus-btn">
                  +
                </Button>
                )}
              </>
            </div>
          </>
        ))
      }
    </div>
  )
}

export default OtherResearchAutoSuggest
